/* .slider {
  width: 100%;
} */
.image {
  width: 100%;
  object-fit: cover; /* Dopasowuje obraz do kontenera, zachowując proporcje */
  object-position: center right; /* Ustawia pozycję obrazu na środek i prawą stronę */
}

.bannerContainer {
  @apply flex flex-row w-full mt-2 md:mt-0 md:p-2 items-center justify-center sm:flex sm:flex-row sm:w-full sm:p-2 sm:items-center sm:justify-center lg:hidden;
}

.bannerImage {
  @apply w-full sm:object-cover;
}
