.button {
  @apply flex items-center justify-center p-[10px] bg-[#e0e0e0] h-[36px] w-full  text-[13px] sm:flex sm:flex-row sm:items-center sm:justify-center sm:p-[10px] sm:bg-[#e0e0e0] sm:h-[36px] sm:w-full  md:h-[52px] lg:w-full;
}

.buttonList {
  @apply flex flex-row w-full items-center mt-4 mb-[30px] justify-center gap-2 sm:flex sm:flex-row sm:w-full sm:items-center sm:mt-4 sm:pl-4 sm:pr-4 sm:gap-4;
}

.button {
  -webkit-box-shadow: 11px 11px 7px -5px rgba(66, 68, 90, 1);
  -moz-box-shadow: 11px 11px 7px -5px rgba(66, 68, 90, 1);
  box-shadow: 5px 5px 7px -5px rgba(66, 68, 90, 1);
  @apply font-semibold uppercase no-underline cursor-pointer border-0 rounded-[4px] bg-[#e0e0e0] duration-400 sm:font-semibold sm:uppercase sm:no-underline sm:cursor-pointer sm:border-0 sm:bg-[#e0e0e0] sm:duration-400;
}

.button:hover,
.buttonActive {
  background: rgb(224, 224, 224);
  background: -moz-linear-gradient(
    90deg,
    rgba(224, 224, 224, 1) 29%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(224, 224, 224, 1) 29%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(224, 224, 224, 1) 29%,
    rgba(255, 255, 255, 1) 100%
  );
  /* box-shadow: none; */
  -webkit-box-shadow: inset 7px 7px 19px -13px rgba(66, 68, 90, 1);
  -moz-box-shadow: inset 7px 7px 19px -13px rgba(66, 68, 90, 1);
  box-shadow: inset 2px 2px 3px 0px #919191;
  @apply sm:text-[#d63744];
}

.outerPostsConatiner {
  @apply flex flex-col border-t-3 gap-4 px-4 md:px-0 sm:flex sm:flex-col w-full mt-2 sm:border-t-3 border-solid border-[#d63744] lg:w-[700px];
}

.posts {
  @apply flex flex-col gap-y-2 w-full my-4 items-center justify-between sm:flex sm:flex-row sm:mt-4;
}

.blog {
  @apply sm:border-t-3 sm:border-solid sm:border-[#d63744];
}
