/* .buttonGroups {
  @apply grid grid-cols-3 grid-flow-row grid-rows-4 gap-8 items-center justify-center w-1/2 list-none p-0 sm:grid sm:grid-cols-4 sm:grid-rows-3 sm:gap-8 sm:items-center sm:justify-center sm:w-3/4 sm:pt-4 sm:pb-4 lg:grid lg:grid-cols-6 lg:grid-rows-2 lg:gap-6 lg:w-[90%] lg:pt-4 lg:pb-4;
} */

.buttonGroups {
  @apply grid grid-cols-4 grid-flow-row items-center justify-center gap-6 md:gap-16 md:gap-y-8 mb-[30px] list-none w-full sm:grid sm:grid-cols-6 sm:grid-flow-row sm:gap-12 sm:gap-y-8 sm:pl-4 sm:pr-4 lg:gap-6 lg:gap-y-8;
}

/* .buttonGroups {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr !important;
  list-style-type: none;
  padding: 0;
  width: 100%;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 35px;
  row-gap: 45px;
  margin-bottom: 30px;
  justify-items: stretch;
}  */
/*
.li {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 50%;
  aspect-ratio: 1 / 1; /* Utrzymuje stały stosunek szerokości do wysokości 
  -webkit-box-shadow: 11px 11px 15px -5px rgba(66, 68, 90, 1);
  -moz-box-shadow: 11px 11px 15px -5px rgba(66, 68, 90, 1);
  box-shadow: 5px 5px 15px -5px rgba(66, 68, 90, 1);
} */

.li {
  @apply flex items-center justify-center box-border cursor-pointer rounded-[50%] aspect-square;
  -webkit-box-shadow: 11px 11px 15px -5px rgba(66, 68, 90, 1);
  -moz-box-shadow: 11px 11px 15px -5px rgba(66, 68, 90, 1);
  box-shadow: 5px 5px 15px -5px rgba(66, 68, 90, 1);
}
.link {
  @apply flex items-center justify-center rounded-[50%] w-full h-full;
}
.li:hover {
  background: rgb(224, 224, 224);
  background: -moz-linear-gradient(
    90deg,
    rgba(224, 224, 224, 1) 29%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(224, 224, 224, 1) 29%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(224, 224, 224, 1) 29%,
    rgba(255, 255, 255, 1) 100%
  );
  -webkit-box-shadow: inset 7px 7px 19px -13px rgba(66, 68, 90, 1);
  -moz-box-shadow: inset 7px 7px 19px -13px rgba(66, 68, 90, 1);
  box-shadow: inset 2px 2px 3px 0px #919191;
  color: #d63744;
}
.selected {
  /* background-color: rgba(58, 58, 58, 0.6); */
  background: rgb(224, 224, 224);
  background: -moz-linear-gradient(
    90deg,
    rgba(224, 224, 224, 1) 29%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(224, 224, 224, 1) 29%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(224, 224, 224, 1) 29%,
    rgba(255, 255, 255, 1) 100%
  );
  -webkit-box-shadow: inset 7px 7px 19px -13px rgba(66, 68, 90, 1);
  -moz-box-shadow: inset 7px 7px 19px -13px rgba(66, 68, 90, 1);
  box-shadow: inset 2px 2px 3px 0px #919191;
}

h3 {
  font-size: 14px;
  margin: 0;
}
