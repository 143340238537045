.post {
  @apply max-w-[70vh] bg-[#d8d8d8] no-underline text-[#3b3b3b] h-auto sm:h-[425px] sm:w-[200px] md:w-[200px];
}
.category {
  @apply text-red-600 text-base sm:text-xs pl-5 font-semibold pt-[10px];
}
.posttitle {
  @apply font-semibold pl-5 text-base sm:text-sm pt-[10px];
}
.postdata {
  @apply text-xs sm:text-[9px] pl-[30px] pt-[10px];
}
.postdesc {
  @apply px-5 my-[12px] flex items-start text-sm sm:text-xs h-auto;
}
.link {
  @apply px-1 w-full text-center font-semibold text-base sm:text-xs p-1 bg-[#d8d8d8] mt-2 rounded-md text-[#3b3b3b];
}

.link:hover {
  @apply text-red-600;
}
